export const SideBarMenu = {
  sales: [
    "Agreements",
    "Create A Key System",
    "Order Authorization",
    "Training & Resources",
  ],
  user: ["AGREEMENTS",
    "CREATE A KEY SYSTEM",
    "ORDER AUTHORIZATION",
    "ELECTRONIC KEY RECORDS",
    "MANAGE YOUR CARDED PROGRAMS",
    "PROGRAM SELECTOR",
    
  //   "Retrofit Search",
  // "FAQs", 
  "TRAINING & RESOURCES",
"AA CONNECT"],
  
};

export const TopNavMenu = {
  menu: ["AGREEMENTS", "FORMS", "COINING DIE GENERATION"],
};

export const agreementPopupLabel = [
  "type",
  "title",
  "firstName",
  "lastName",
  "companyName",
  "address",
  "address-2",
  "city",
  "state",
  "postalCode",
  "emailAddress",
  "phoneNumber",
  "agreementType"
];

export const fields = { 
  register: [
    {userType: "User Type"},
    {firstName: "First Name"},
    {lastName: "Last Name"},
    {emailAddress: "Email Address"},
    {companyName: "Company Name"},
  ]
}
